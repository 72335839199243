/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import {
  Router, Route, Switch,
} from 'react-router-dom';
import history from '../../history';
import {
  ErrorPageContainer,
} from '..';

import {
  MainSite,
} from '../../components';

import './app-container.scss';

type AppContainerTypes = {} & typeof defaultProps;
const defaultProps = {};

const AppContainer = (props: AppContainerTypes) => (
  <MainSite />
);

AppContainer.defaultProps = defaultProps;

export default AppContainer;
