/* eslint-disable */
import React, { useEffect, useState } from 'react';
import './map-item-popup.scss';

const MapItemPopup = (props) => {
  const {
    posX = "50%",
    posY = "50%",
    title = "Example Title",
    copy = "Example copy",
    toggleCopy,
    isMobile,
    isMobileAlignLeft,
    isMobileAlignLeftArrow,
    videoContent,
    closeActivePopups,
    activeRoadmapPopup,
    popupId,
    bgColor,
  } = props;
  const [isPopupActive, setIsPopupActive] = useState(false);

  const toggleMapItem = (isHover) => {
    if (isHover && isMobile) return;
    if (isHover && isPopupActive) return;
    closeActivePopups();
    setIsPopupActive(!isPopupActive);
  }

  useEffect(() => {
    if (popupId !== activeRoadmapPopup) setIsPopupActive(false);
  }, [activeRoadmapPopup]);

  useEffect(() => {
    const browserWidth = window.innerWidth;
    if (browserWidth < 1000) return
    if (popupId === activeRoadmapPopup) {
      setIsPopupActive(true);
    }
  }, []);

  return (
    <div
      className={`map-item-popup ${isMobile ? 'is-mobile' : ''}`}
      style={{left: posX, top: posY, zIndex: isPopupActive ? 1000 : 1}}
    >
      {isPopupActive ? (
        <div
          className="active-popup-container"
          style={isMobile ? {left: isMobileAlignLeft} : {}}
        >
          {videoContent ? (
          <video autoPlay loop muted playsInline>
            <source src={videoContent} type="video/mp4" />
          </video>
          ) : <></>}
          <h3>{title}</h3>
          <span>{copy}</span>
          <div
            className="arrow-bottom"
            style={isMobile ? {left: isMobileAlignLeftArrow} : {}}
          />
        </div>
      ) : <></>}
      <div
        className="map-item-popup-toggle"
        onClick={() => toggleMapItem()}
        onMouseEnter={() => toggleMapItem(true)}
        // onMouseLeave={() => toggleMapItem()}
        style={isPopupActive ? {} : {backgroundImage: "unset"}}
      >
        <div
          className="inner-pulse"
          style={isPopupActive ? {opacity: 0} : {backgroundColor: bgColor}}
        />
      </div>
    </div>
  );
};

export default MapItemPopup;
