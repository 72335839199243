import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './error-page-container.scss';

type ErrorPageContainerTypes = {} & typeof defaultProps;
const defaultProps = {};

const ErrorPageContainer = (props: ErrorPageContainerTypes) => {
  const history = useHistory();

  function handleClick() {
    history.push('/');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="error-page-container">
      <h1>Error Page Container</h1>
    </div>
  );
};

ErrorPageContainer.defaultProps = defaultProps;

export default ErrorPageContainer;
