/* eslint-disable no-useless-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './email-popup-component.scss';

const EmailPopupComponent = (props) => {
  const { toggleEmailPopup } = props;
  const [animatePopup, setAnimatePopup] = useState(false);
  const [enablePopup, setEnablePopup] = useState(true);

  const [emailInput, setEmailInput] = useState('');
  const [emailInputValid, setEmailInputValid] = useState(false);
  const [tickBoxOneActive, setTickBoxOneActive] = useState(true);
  const [tickBoxTwoActive, setTickBoxTwoActive] = useState(true);

  const updateEmailInput = (e) => {
    const input = e.target.value;
    setEmailInput(input);
    if (input.length > 5) {
      setEmailInputValid(true);
    } else {
      setEmailInputValid(false);
    }
  };

  const tickboxOneToggle = () => {
    setTickBoxOneActive(!tickBoxOneActive);
  };

  const tickboxTwoToggle = () => {
    setTickBoxTwoActive(!tickBoxTwoActive);
  };

  const postData = () => new Promise((resolve) => {
    const submissionData = {
      emailInput: `AUTOMOBILIST|${emailInput}`,
      agreeToTermsAndConditions: 'TRUE',
      recaptchaToken: 'N/A',
      contentType: 'N/A',
      artistNameInput: 'N/A',
      socialInput: 'N/A',
      originalFileName: 'N/A',
    };

    Axios.post(
      'https://4b1dpmmxnc.execute-api.ap-southeast-2.amazonaws.com/dev/submission',
      submissionData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then(async (response) => {
      toast.success('Thanks! Your email has been successfully registered.');
      resolve(true);
    }).catch((error) => {
      toast.error('There was an error processing your email registration. Please try again and/or contact us over Discord.');
      setEnablePopup(true);
      resolve(false);
    });
  });

  const submitEmail = async () => {
    if (!enablePopup) return;

    setEnablePopup(false);

    if (!emailInputValid) {
      toast.error('Please enter a valid email.');
      setEnablePopup(true);
      return;
    }

    if (!tickBoxOneActive || !tickBoxTwoActive) {
      toast.error('You must agree to receive marketing communications.');
      setEnablePopup(true);
      return;
    }

    const postResult = await postData();

    if (postResult) {
      setTimeout(() => {
        toggleEmailPopup();
      }, 3000);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimatePopup(true);
    }, 100);
  }, []);

  return (
    <>
      <div className="email-popup-component">
        <ToastContainer position="top-left" autoClose={15000} />
        <div className={`email-popup-container ${animatePopup ? 'email-popup-container-show' : ''} ${enablePopup ? '' : 'disabled-popup'}`}>
          <div className="close-button" onClick={() => toggleEmailPopup()} />

          <h3>
            Register your email to
            <br />
            receive project updates
          </h3>

          <input
            placeholder="Enter your email"
            onChange={(e) => updateEmailInput(e)}
            value={emailInput}
            spellCheck={false}
          />

          <div className="confirm-email-comms">
            <div className="tickbox-container" onClick={() => tickboxOneToggle()}>
              <div className={`tickbox ${tickBoxOneActive ? 'tickbox-active' : ''}`} />
              <span>Receive project related marketing</span>
            </div>
            <div className="tickbox-container" onClick={() => tickboxTwoToggle()}>
              <div className={`tickbox ${tickBoxTwoActive ? 'tickbox-active' : ''}`} />
              <span>Receive marketing from Run It Wild and partners</span>
            </div>
          </div>

          <div className="submit-button" onClick={() => submitEmail()}>
            {enablePopup ? 'REGISTER' : 'LOADING'}
          </div>

        </div>
        <div className="email-popup-background" />
      </div>
    </>
  );
};

export default EmailPopupComponent;
